import type { Ref } from '@vue/composition-api'

// Usage 'min:3'
// eg: const rules = ref({
//  name: 'required|min:3',
//  surname: 'required|min:3',
//  quantity: 'numeric|min:3',
// })
const min = (form: Ref<any>, key: string, min: string): boolean => {
  if (typeof form.value[key] === 'number') {
    return form.value[key] >= parseInt(min)
  }

  return form.value[key].length >= parseInt(min)
}

export default min
