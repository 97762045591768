import { type Ref } from '@vue/composition-api'

// Usage 'regex:\\d+'
// eg: const rules = ref({
//  name: 'required|min:3',
//  surname: "required|array|regex:^\\d{2}\\.\\d{2}\\.\\d{4}(?:\ \\d{2}:\\d{2})?\$",
// })
const regex = (form: Ref<any>, key: string, regex: string): boolean => {
  if (Array.isArray(form.value[key])) {
    return form.value[key].every((value) => new RegExp(regex).test(value))
  }
  return new RegExp(regex).test(form.value[key])
}

export default regex
